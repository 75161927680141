.dropdown-menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px; /* Bordes redondeados */
  padding: 8px;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.671);
  backdrop-filter: blur(3px);
}


  .tabla-menu {
    /*background-color: rgba(0, 0, 0, 0.507); /* Color de fondo con transparencia */
    /*backdrop-filter: blur(4px); /* Aplica un efecto de difuminado */
    /*width: 100%;*/
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    /*background-color: green;*/
    min-height: 98vh;
  }
  
  
  .dropdown-menu.open {
    display: block;
  }
  
  .dropdown-menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .dropdown-menu ul li {
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .dropdown-menu ul li:hover {
    background-color: #f2f2f200; /* Cambia el color de fondo al pasar el mouse */
  }
  
  .dropdown-menu button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .icono-header {
    background-color: rgba(73, 73, 73, 0);
    /*backdrop-filter: blur(1px);*/
    color: white;
    font-size: 2.3rem;
    margin: 0;
    border-radius: 5px;
    padding: 5px;
  }

  .container-options {
    background-color: rgba(0, 0, 0, 0.397);
    backdrop-filter: blur(8px);
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    color: white;
  }
  