/* Proveedores.scss */
.container-proveedoress {
    display: flex;
    background-color: red;
    min-width: 100%;
    //background-color: red;
    //background-color: rgb(238, 0, 255);
    //min-width: 193vh;
    //justify-content: center;
    //align-items: center;
  }

  .header-prov {
    background-color: rgba(0, 0, 0, 0.038);
    //padding: 5px;
    margin-left: 3%;
    margin-top: 18px;
    height: 7vh;
    min-width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    //background-color: rgb(255, 0, 0);
    border-radius: 5px;
  }

  .input-buscador {
    //background-color: green;
    padding: 5px;
    border-radius: 5px;
    border-width: 2px;
    width: 50%;
  }

  .h1-prov {
    font-size: 1.5rem;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: bolder;
  }

  .add-prov {
    //background-color: red;
    font-size: 2.6rem;
    padding: 3px;
    border-radius: 50%;
    border-width: 1px;
    border-color: black;
  }
  
  .card-proveedor {
    padding: 20px;
    border-radius: 8px;
    //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    //background-color: rgba(128, 128, 128, 0.062);
    min-width: 100%;
    height: auto;
    //border-width: 3px;
    border-color: orangered;
    //background-color: rgb(0, 255, 0);
  }
  
  .nombre-proveedor {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bolder;
    text-decoration: overline;
  }
  
  .image-proveedor {
    //background-color: red;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
  }

  .producto-cap-proveedores{
    border-width: 1px;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 250px;
    height: auto;
    padding: 10px;
    border-radius: 15px;
    border-width: 2px;
    //border-color: orange;
    //background-color: rgba(0, 0, 0, 0.938);
  }

  .productos-proveedor {
    //background-color: rgb(9, 255, 0);
    background-color: blue;
    min-width: 100%;
    max-height: auto; 
    overflow-y: auto;
    display: flex;
    //flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 20px;
    margin-right: 2px;
  }

  .proveedores-factura {
    background-color: green;
    border-width: 0 0 0 0px;
    margin: 8px;
    max-width: 100%;
    height: 98vh;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    padding: 10px;
    min-width: 30%;
  }
  
  .incrementar-cantidad {
    //background-color: red;
    height: 5vh;
    width: 100%;
  }

  .incrementar-cantidad {
    //background-color: yellow;
    justify-content: space-around;
    height: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ingresar-cantidad {
    //border-width: 2px;
    //border-color: rgba(255, 68, 0, 0.422);
    background-color: rgba(204, 204, 204, 0.527);
    border-width: 0px;
    border-color: black;
    border-radius: 10px;
    padding: 5px;
    width: auto;
    margin-bottom: 10px;
    text-align: center;
  }

  .total-proovedor-pagar {
    width: 70%;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
  }

  .titulo-producto-pro {
    //background-color: red;
    font-size: 1.3rem;
    font-weight: bolder;
    margin-bottom: 5px;
    font-family: 'Noto Sans Display', sans-serif;
  }

  .precio-producto-pro {
    //background-color: red;
    font-size: 1.3rem;
    font-weight: bolder;
    margin-top: 5px;
    font-family: 'Noto Sans Display', sans-serif;
  }

  .guardar-pago-prov {
    background-color: rgb(255, 0, 0);
    color: white;
    margin-top: 15px;
    height: 5vh;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .select-pro{
    //background-color: rgb(0, 255, 30);
    height: 6vh;
    width: 100%;
    border-radius: 80px;
    margin-bottom: 10px;
    //border-width: 2px;
    border-color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .select-pro2 {
    //background-color: red;
    width: 50%;
    height: 4vh;
    border-radius: 10px;
    border-width: 2px;
    border-color: black;
  }

  .select-usuario-prov {
    width: 40%;
    height: 4vh;
    border-radius: 10px;
    border-width: 2px;
    border-color: black;
  }

  .menu-pro {
    font-size: 2.5rem;
  }

  .valor-descuento {
    margin-bottom: 10px;
  }

  .check-pro {
    color: green;
    //background-color: red;
    font-size: 2rem;
    padding: 3px;
    border-radius: 50%;
    border-width: 2px;
    border-color: green;
    margin-top: 7px;
    cursor: pointer;
  }

  .info-producto-seleccionado {
    //background-color: rgba(0, 0, 0, 0.096);
    border-width: 3px;
    padding: 5px;
    border-radius: 5px;
    min-height: 12vh;
    width: calc(100% - 20px); // El ancho será el 50% del contenedor menos el espacio del margen
    margin: 10px; // Espacio entre los elementos
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .capsula-producto-seleccionado {
    min-height: 80vh;
    min-width: 100%;
    overflow-y: auto;
    //background-color: red;
  }
  
  .seleccionado-prov {
    //background-color: orange;
    border-width: 5px;
    background-color: rgba(255, 166, 0, 0.048);
  }

  .info-cap {
    //background-color: red;
    height: 100%;
    padding: 10px;
    text-align: center;
    min-width: 80%;
    display: flex;
    justify-content: space-around;
  }

  .opciones-factura-pro {
    //background-color: green;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 15vh;
    font-size: 2rem;
  }

  .trash-pro {
    background-color: rgb(255, 68, 0);
    padding: 3px;
    border-radius: 50%;
    color: black;
    font-size: 2rem;
    cursor: pointer;
    &:hover {
      background-color: black;
      color: white;
    }
  }

  .cantidad-pro {
    //background-color: orange;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    min-width: 35px;
    height: 35px;
    font-size: 1.2rem;
    font-weight: bolder;
  }

  .guardar-factura-pro {
    background-color: rgba(0, 0, 0, 0.048);
    border-radius: 80px;
    height: 6vh;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .final-pro {
    //background-color: green;
    border-width: 3px;
    //border-color: orange;
    min-width: 20%;
    text-align: center;
    padding: 3px;
    border-radius: 5px;
  }
  
  .cantidad-final-pro {
    //background-color: rgba(0, 0, 0, 0.096);
    border-width: 3px;
    //border-color: orange;
    min-width: 40px;
    min-height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;  
    align-items: center;
  }

  .no-prov {
    //background-color: red;
    font-size: 15rem;
    margin-bottom: 20px;
  }