@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto+Serif:opsz,wght@8..144,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@200&display=swap');
@import "~video-react/styles/scss/video-react";

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  //overflow: hidden;
  max-height: auto;
  //background-color: red;
}

.toda {
  //background-color: red;
  //display: flex;
  max-height: auto;
  width: 100%;
  //overflow: hidden;
  //background-color: red;
}

.powered-by {
  display: flex;
  justify-content: space-around;
  align-items: center;
  //background-color: black;
  //margin-left: 30px;
  width: 100%;
}

.play {
  //background-color: red;
  color: black;
  font-size: 4rem;
}

.train {
  padding: 10px;
  border-radius: 40px;
  //background-color: rgba(0, 0, 0, 0.712);
  //backdrop-filter: blur(8px);
  text-align: center;
  font-weight: bolder;
  color: black;
  font-family: 'Noto Sans Display', sans-serif;
  font-size: 15px;
  width: auto;
}

.trainMini {
  color: rgba(128, 128, 128, 0.719);
  font-size: 1rem;
}

.content {
  //z-index: 1; /* Asegura que el contenido esté sobre el fondo */
  color: white;
  //background-color: green;
  width: 100%;
}

.left-black {
  //background-color: black;
  //height: 100%;
  border-radius: 20px;
  //background-color: red;
}

.inicio{
  height: auto;
  //background-color: green;
}


.title {
  //font-size: 4rem;
  text-align: center;
  color: black;
  font-family: 'Noto Sans Display', sans-serif;
  line-height: 1;
}

.seccion-titulo {
  //background-color: red;
  //margin-bottom: 5px;
  padding: 2px;
  margin: 1px;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.images-seccion {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  //background-color: green;
  height: auto;
}

.¿ {
  color: rgb(68, 68, 68);
  font-size: 5rem;
  &:hover {
    color: rgb(112, 112, 112);
  }
}

.contenedor-login {
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  margin-top: 0%;
  //background-color: rgba(0, 0, 0, 0.123);
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contenedor2-login {
  width: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 0%;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0px;
  justify-content: space-around;
  align-items: center;
}

.circulo1 {
  background-color: rgb(0, 0, 0);
  height: 35vh;
  width: 35vh;
  border-radius: 50%;
}

.circulo2 {
  background-color: rgb(0, 0, 0);
  height: 25vh;
  width: 25vh;
  border-radius: 50%;
}

.circulo3 {
  background-color: rgb(0, 0, 0);
  height: 15vh;
  width: 15vh;
  border-radius: 50%;
}

.contenedor-credenciales {
  //background-color: rgba(255, 0, 0, 0.607);
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
  width: 100%;
}

.input-credenciales {
  width: 90%;
  border-radius: 5px;
  padding-left: 10px;
  height: 5vh;
  color: black;
  font-size: 1.2rem;
  border: none; /* Establece el borde a ninguno */
  outline: none; /* Elimina el contorno al hacer clic */
  border-color: black;
  //background-color: blue;
}

.error-message {
  background-color: rgba(255, 0, 0, 0.6);
  width: auto;
  display: flex;
  //justify-content: center;
  align-items: center;
  border-radius: 10px;
  justify-content: right;
  top: 58%;
  left: 80%;
}

.linea-ingresar {
  //background-color: rgb(43, 255, 0);
  border-radius: 7px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 30vh;
}

.recuadro-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(65, 65, 65);
  padding: 5px;
  border-radius: 8px;
}

h1 {
  color: black;
}

.spin {
  animation: spin 1s linear infinite; /* Define la animación llamada "spin" */
}

.ingresar-boton {
  //background-color: rgb(8, 0, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.div-sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //background-color: red;
  width: 110%;
  margin-top: 20px;
  color: black;
}

.imageLogin {
  border-radius: 30px;
  border-width: 2px;
  border-color: black;
  height: 100%;
  width: 100%;
}

.modal-login-view {
  position: fixed;
  display: flex;
  flex-direction: space-between;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  border-radius: 20px;
  //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  max-width: 400px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  position: relative;
  z-index: 1000;
  background-color: rgb(255, 255, 255);
}


.modal-login-view-2 {
  //background-color: rgba(90, 90, 90, 0.405);
  border-radius: 20px;
  height: auto;
  text-align: center; 
}

.overlay-login-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  background-color: rgba(165, 165, 165, 0.71);
  backdrop-filter: blur(3px);
}

.modal-login-view::before {
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.modal-login-view::after {
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.suscribir {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  //margin-top: 20px;
  //margin-bottom: 20px;
}

.suscribir2 {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  //margin-top: 20px;
  //margin-bottom: 20px;
}

.suscribir-cerrar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  //background: red;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 20px;
  color: black;
}

.simbolo-peso {
  font-weight: bold; /* Hace el precio en negrita */
  color: rgb(0, 0, 0); /* Color del precio, ajusta según necesites */
  font-size: 30px; /* Tamaño de fuente del precio */
}


.por-mes {
  display: block; /* Hace que 'por' y 'mes' estén en líneas separadas */
  color: rgb(0, 0, 0); /* Color del texto 'por mes' */
  font-size: 30px; /* Tamaño de fuente para 'por mes' */
  font-style: italic; /* Estilo itálico para 'por mes' */
  height: auto;
}

.div-precio {
  font-size: 24px; /* Ajusta el tamaño de fuente general del precio */
  color: black; /* Color del texto del precio */
  background-color: rgba(0, 119, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
}

.por-mes-div {
  background-color: rgba(0, 0, 0, 0);
}

.por-mes {
  //background-color: blue;
  line-height: 0.5;
  color: gray;
  margin-left: 5px;
}

.suscribir:hover {
  background-color: #444;
}

.especificaciones-planes {
  background-color: rgba(0, 0, 0, 0.027);
  border-radius: 30px;
  max-height: 45vh;
  overflow-y: auto;
}

.subscription-info {
  margin: 20px 0;
  text-align: left;
  ///background-color: blue;
}

.subscription-info h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.subscription-info p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.subscription-info ul {
  list-style: none;
  padding: 0;
}

.subscription-info ul li {
  display: flex;
  justify-content: left;
  align-items: center;
  //background: #ff0000;
  border-width: 0 0 1px 0;
  border-color: black;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 0px;
}

.titulo-sub {
  //background-color: black;
  border-radius: 10px;
  color: black;
  font-size: 2rem;
  font-weight: bolder;
}
