.factura-list-container {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center; /* Añade esto para centrar verticalmente */
  //background-color: red;
}
    
.factura-item {
  position: relative;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 250px;
  text-align: center;
  border-radius: 20px;
  padding: 40px;
  color: black;
  margin-bottom: 15px;
  transition: transform 0.3s ease, top 0.3s ease, left 0.3s ease;
  font-family: 'Noto Sans Display', sans-serif;
  font-weight: bold;
}


.factura-item.expand {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.1); /* Agranda la factura y la centra */
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Mayor sombra para el efecto de elevación */
  //background-color: red;
  min-width: 350px;
}

.overlay-facturas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.678);
  z-index: 10;
  backdrop-filter: blur(2px);
}

.icono-enviar {
  position: absolute;
  top: 92%;
  left: 85%;
  //background-color: red;
  display: flex;
  align-items: right;
  justify-content: right;
  font-size: 2rem;
  //margin-top: 10px;
  //text-align: right;
}

.icono-editar {
  position: absolute;
  top: 92%;
  left: 2%;
  //background-color: red;
  display: flex;
  align-items: right;
  justify-content: right;
  font-size: 2rem;
  //margin-top: 10px;
  //text-align: right;
}

.icono-enviar2 {
  cursor: pointer;
  &:hover {
    cursor: pointer;
    font-size: 2.5rem;
  }
}

.icono-enviar3 {
  color: green;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    font-size: 2.5rem;
  }
}

.iconos-enviar {
  //background-color: red;
  display: flex;
  justify-content: space-between;
  height: 6vh;
  align-items: center;
}

.btn-enviar-factura {
  //background-color: red;
  font-size: 2rem;
  margin-top: 40px;

  &:hover {
    cursor: pointer;
    font-size: 2.5rem;
  }
}

  .productos-factura {
    //background-color: rgb(115, 255, 0);
    height: 20vh;
    margin-right: 5px;
    width: 100%;
    overflow-y: auto;
  }
  

  .cliente-seccion {
    //background-color: red;
    text-align: left;
    font-size: 0.7rem;
  }

  .header-tabla {
    //background-color: red;
    font-size: 0.7rem;
  }
  
  .header-body {
    //background-color: rgb(255, 196, 0);
    font-size: 0.7rem;
  }

  .seccion-derecha {
    //background-color: red;
    text-align: right;
    font-size: 0.9rem;
    font-weight: bolder;
  }

  .seccion-derecha2 {
    //background-color: red;
    text-align: right;
    font-size: 0.8rem;
  }

  .linea-factura {
    height: 2px;
    background-color: black;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .titulo-restaurante {
    font-weight: bolder;
    font-family: 'Noto Sans Display', sans-serif;
    font-size: 1.2rem;
    
  }

  .p-sede {
    font-size: 0.8rem;
  }

  footer {
    //background-color: red;
    font-size: 0.5rem;
    margin-top: 5%;
  }

  .input-factura {
    //background-color: red;
    margin-left: 5px;
    width: 100px;
    text-align: center;
    border-radius: 10px;
    font-weight: bolder;
    color: black;
    border-width: 2px;
    border-color: red;
    margin: 5px;
  }

  .btn-edit {
    background-color: rgb(0, 0, 0);
    color: white;
    font-weight: bolder;
    border-radius: 50px;
    padding: 5px;
    margin-top: 5px;
    width: 70px;
  }

  .btn-save {
    background-color: rgb(255, 0, 0);
    color: white;
    font-weight: bolder;
    border-radius: 8px;
    padding: 5px;
    margin-top: 5px;
  }
