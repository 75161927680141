/* Container Styling */
.main-success {
    background-color: rgba(0, 128, 0, 0);
    width: 100%;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lado-izquierdo-suc {
    background-color: rgb(255, 255, 255);
    border-width: 0 5px 0 0;
    border-color: black;
    color: white;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    background-color: #33ff0000;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    overflow-y: hidden;
}

/* Step Container Styling */
.step-container {
    margin-bottom: 50px;
    padding: 10px;
    height: 50vh;
    width: 100%;
    background-color: rgba(255, 0, 0, 0);
    border-radius: 10px;
    color: black;
    border-color: black;
}

.div-circles-suc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100%;
}

.input-nombre-emp {
    background-color: rgba(255, 0, 0, 0);
    width: 50%;
    text-align: center;
    border-width: 3px;
    outline: none; /* Esto elimina el borde por defecto del input */
    font-size: 1.8rem;
}

.input-nombre-emp:focus {
    outline: none; /* Esto asegura que no haya borde cuando el input está enfocado */
    border: none;  /* Esto asegura que no haya borde adicional cuando el input está enfocado */
}

.circle-logo {
    background-color: rgb(0, 0, 0);
    height: 300px;
    width: 300px;
    margin: 20px;
    border-radius: 50%;
}

.circle-logo2 {
    background-color: rgb(0, 0, 0);
    height: 200px;
    width: 200px;
    margin: 20px;
    border-radius: 50%;
}


.circle-logo3 {
    background-color: rgb(0, 0, 0);
    height: 100px;
    width: 100px;
    margin: 20px;
    border-radius: 50%;
}

.step {
    background-color: rgba(255, 0, 0, 0);
    max-height: 85vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contenedor-sede {
    display: flex;
    flex-direction: column;
    height: 85vh;
    width: 100%;
    background-color: rgb(64, 255, 0);
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
}


.secciones-varios {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
}

.buton-next {
    border-radius: 8px;
    padding: 5px;
    color: white;
    background-color: rgb(255, 255, 255);
    border-color: black;
    font-size: 3rem;
}

.agregar-suc {
    background-color: black;
    color: white;
    height: 6vh;
    border-radius: 8px;
    padding: 0 8px 0 8px;
    margin-right: 40px;
}

.boton-eliminar-suc {
    position: absolute;
    top: 20px;
    right: 25px;
    background-color: red;
    font-size: 2rem;
    border-radius: 8px;
    color: white;
    
}
.contenedor-sede {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    width: 58%;
    background-color: rgba(228, 228, 228, 0.719);
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
}

.botones-back {
    background-color: rgb(255, 255, 255);
    width: 120px;
}

.terminar-emp {
    background-color: rgb(255, 255, 255);
    width: 230px;
}

.buton-back {
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    border-radius: 8px;
    width: 60px;
}

.input-usuario {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-width: 2px;
    border-color: black;
    width: 150px;
    text-align: center;
    padding: 5px;
    border-radius: 8px;
}

.buton-crear-empresa {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 6vh;
    width: 150px;
    border-radius: 20px;
}

.input-usuario:focus {
    outline: none; /* Esto asegura que no haya borde cuando el input está enfocado */
      /* Esto asegura que no haya borde adicional cuando el input está enfocado */
    }

.step-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espacio entre los contenedores */
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    height: 50vh;
  }
  
  .contenedor-sede2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 0, 0, 0);
    background-image: var(--icon-bg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 318px; /* Ajusta según tus necesidades */
    width: 280px; /* Ajusta según tus necesidades */
    padding: 20px;
    box-sizing: border-box;
  }

  .input-nombre-sed {
    background-color: rgb(255, 255, 255);
    height: 3.5vh;
    width: 20px;
    text-align: center;
    border-width: 2px;
    width: 100%;
    border-color: black;
    border-radius: 3px;
    padding: 10px;
  }

  .input-nombre-sed:focus {
    outline: none; /* Esto asegura que no haya borde cuando el input está enfocado */
      /* Esto asegura que no haya borde adicional cuando el input está enfocado */
    }

  .dentro-casa {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 0, 0, 0);
    height: auto;
    width: 125px;
    margin-top:80px;
  }
  
  

/* Circle Stage Styling */
.circle-stage {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transform: scale(0);
}

.circle-stage.active {
    display: block;
    opacity: 1;
    transform: scale(1);
}

/* Circle Container */
.circles {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.circle {
    width: 100px;
    height: 100px;
    background-color: #333;
    border-radius: 50%;
    animation: circleAnimation 2s infinite;
}

.circle2 {
    width: 60px;
    height: 60px;
    background-color: #333;
    border-radius: 50%;
    animation: circleAnimation 2s infinite;
}

.circle3 {
    width: 30px;
    height: 30px;
    background-color: #333;
    border-radius: 50%;
    animation: circleAnimation 2s infinite;
}

.circle:nth-child(1) {
    animation-delay: 0.9s;
}

.circle:nth-child(2) {
    animation-delay: 1s;
}

.circle:nth-child(3) {
    animation-delay: 1.1s;
}

/* Input Styling */
.container label {
    display: block;
    margin: 10px 0 5px;
    color: #333;
}

.container inpuet {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid #000000;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Button Styling */


.container button:hover {
    background-color: #555;
}




/* Animation for Circles */
@keyframes circleAnimation {
    0% { transform: scale(0); opacity: 0; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0); opacity: 0; }
}

.divisor {
    height: 2vh;
    color: black;
    background-color: black;
}

/* ProgressBar.css */
.progress-bar-container {
    position: fixed;
    top: 20px;
    right: 25%;
    width: 200px;
    height: 10px;
    background-color: #b6b6b6;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #000000;
    transition: width 0.3s;
  }

  .creando-empresa {
    position: fixed;
    top: 40px;
    right: 26.5%;
  }



  /* Modal.css */
.modal-overlay-suc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container-suc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
  }
  
  .modal-check-icon {
    font-size: 5rem;
    color: green;
    transition: background-color 1s ease;
    background-color: transparent;
    border-radius: 50%;
  }
  
  .modal-container-suc h2 {
    margin-top: 10px;
    font-size: 1.5rem;
    color: black;
  }
  
  .modal-button-suc {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .modal-button-suc:hover {
    background-color: #45a049;
  }
  
  
  