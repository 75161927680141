:root {
  --background: hsl(222.2, 84%, 4.9%);
  --foreground: hsl(210, 40%, 98%);
  --card: hsl(222.2, 84%, 4.9%);
  --card-foreground: hsl(210, 40%, 98%);
  --popover: hsl(222.2, 84%, 4.9%);
  --popover-foreground: hsl(210, 40%, 98%);
  --primary: hsl(217.2, 91.2%, 59.8%);
  --primary-foreground: hsl(222.2, 47.4%, 11.2%);
  --secondary: hsl(217.2, 32.6%, 17.5%);
  --secondary-foreground: hsl(210, 40%, 98%);
  --muted: hsl(217.2, 32.6%, 17.5%);
  --muted-foreground: hsl(215, 20.2%, 65.1%);
  --accent: hsl(217.2, 32.6%, 17.5%);
  --accent-foreground: hsl(210, 40%, 98%);
  --destructive: hsl(0, 62.8%, 30.6%);
  --destructive-foreground: hsl(210, 40%, 98%);
  --border: hsl(217.2, 32.6%, 17.5%);
  --input: hsl(217.2, 32.6%, 17.5%);
  --ring: hsl(224.3, 76.3%, 48%);
}


.main-analitica {
  //background-color: black;
  color: black;
  max-height: auto;
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
  overflow-y: auto;
}

.hola {
  //background-color: blue;
  min-width: 100%;
  padding-inline: 50px;
}

.header-analitica {
  //background-color: red;
  margin-bottom: 10px;
}

.ventas-efectivo {
  //background-color: var(--primary);
  border-width: 2px;
  border-color: var(--primary);
  margin: 0 0 50px 0;
  //border-radius: 10px;
  color: black;
  font-weight: bolder;
  padding: 5px;
  min-width: 48%;
}

.total-vendido {
  //background-color: red;
  //border-width: 1px;
  border-color: var(--primary);
  //background-color: rgba(0, 0, 0, 0.044);
  //background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  min-width: 50%;
}

.gastos {
  //background-color: var(--muted-foreground);
  //background-color: rgba(255, 0, 0, 0.574);
  //border-radius: 10px;
  //border-width: 5px;
  border-color: red;
  color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ventas-totales {
  background-color: var(--primary-foreground);
  border-radius: 10px;
  padding: 10px;
}

.totales {
  font-size: 2rem;
  color: var(--background);
  min-width: 50%;
}

.capsula-prov {
  background-color: black;
  border-color: var(--primary);
  height: 10vh;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 50px;
  color: white;
  font-weight: bolder;
  margin: 10px;
}

.capsula-lista-prov {
  //background-color: green;
  min-height: 30vh;
  max-height: 30vh;
  overflow-y: auto;
  //padding-right: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: none; /* Para que haya espacio entre las cápsulas */
}


.total-prov {
  color: black;
  font-size: 1.5rem;
  font-weight: bolder;
}

.nomina-prov {
  //display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-height: auto;
  overflow-y: auto;
  //background-color: red;
}

.carta-adentro {
  //background-color: red;
  min-height: 40%;
  min-width: 100%;
  border-width: 0 0 3px 0;
  border-color: rgb(0, 0, 0);
  padding: 5px;
  border-radius: 0;
}

.ventas-totales2 {
  //background-color: var(--primary);
  border-width: 2px;
  border-color: black;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  margin: 5px;
  height: 12vh;
  color: var(--background);
}

.select-usuario {
  background-color: black;
  border-radius: 5px;
  font-size: 1rem;
  border-width: 0;
  text-decoration: none;
  color: white;
  font-weight: bolder;
  padding: 5px;
}

.cap-fiados {
  //background-color: rgba(255, 0, 0, 0.116);
  height: 10vh;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0px 0px 10px 0px;
  border-width: 3px;
  border-color: var(--primary);
  //background-color: red;
}

.fiados-carta {
  //background-color: rgb(243, 0, 0);
  //border-width: 1px;
  border-color: gray;
  border-radius: 10px;
  max-height: 60vh;
  overflow-y: auto;
  //padding: 10px;
  //background-color: red;
  padding: 10px;
}

.asd {
  //background-color: green;
  text-align: center;
  display: flex;
  justify-content: center;
}

.fiado-text-n {
  font-size: 0.9rem;
  font-weight: bolder;
}

.fiado-text-t {
  font-size: 1.2rem;
  font-weight: bolder;
}

.sumatoria-fiados {
  //background-color: red;
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  color: black;
  font-weight: bolder;
}

.total-fiados {
  color: red;
  font-size: 1.5rem;
  font-weight: bolder;
}