.ContainerProducto {
  position: relative;
  display: flex;
  //flex-direction: column;
  align-items: center;
  justify-content: center;
  //border-radius: 10px;
  border-color: black;
  //padding: 15px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;
  //border-color: rgba(81, 81, 81, 0.273);
  overflow: hidden;
  min-height: 32vh;
  //background-color: rgba(0, 0, 0, 0.189);
  //backdrop-filter: blur(8px);
  min-width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.212);
  border-radius: 20px;
  &:hover {
    transform: translateY(1px) scale(1.04);
  }
}

.InfoContainer1 {
  position: relative;
  z-index: 0;
  color: rgb(34, 34, 34);
  //background-color: rgba(255, 255, 255, 0.996);
  border-radius: 10px;
  top: 1%;
  height: auto;
  width: 100%;
}

.warning-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  font-size: 30px;
}

.warning-icon2 {
  position: absolute;
  top: 5px;
  left: 8px;
  color: black;
  font-weight: bolder;
  font-size: 30px;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line {
  background-color: black;
  height: 2px;
  color: black;
  width: 100%;
  margin-top: 5px;
  //padding-top: 10px;
}
.default-imagee {
  font-size: 75px;
  color: black;
}

.titulo1 {
  font-size: 1rem;
  text-align: center;
  //font-family: 'Noto Sans Display', sans-serif;
  //font-weight: bolder;
  //font-weight: bolder;
}

.Precio {
  font-size: 0.5rem;
  //color: red;
  font-weight: bold;
  font-family: 'Noto Sans Display', sans-serif;
}

.ContainerProducto.seleccionado {
  border-width: 5px;
  border-color: rgb(255, 0, 0);
  //background-color: red;
  border-radius: 20px;
  //background-color: rgb(233, 233, 233);
}

.ContainerProducto:hover {
  cursor: pointer;
}

.image-container-services {
  //background-color: red;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}


.info-card {
  //background-color: red;
  //width: 100%;
  height: 100%;
}

.todo-card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-services {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: white;
}

.InsumosContainer {
  width: 100%;
  //background-color: rgba(0, 0, 0, 0.148);
  //margin-top: 10px;
  max-height: 26vh;
  overflow-y: auto;
  padding: 6px;
  //background-color: red;
}

.NombreInsumo {
  //background-color: rgba(0, 0, 0, 0.179);
  //border-radius: 20px;
  min-height: 6vh;
  width: 100%;
  border-width: 0 0 2px 0;
  border-color: rgb(0, 0, 0);
  font-size: 0.9rem;
  text-align: center;
  //margin-bottom: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-family: 'Noto Sans Display', sans-serif;
}

.NombreInsumo.selected {
  background-color: black; /* Ejemplo de color para selección */
  //border: 1px solid #17a2b8; /* Ejemplo de borde para selección */
  color: white; 
}