@tailwind base;
@tailwind components;
@tailwind utilities;

.main-inventario {
  //background-color: red;
  min-height: 100vh;
  //margin-inline: 5%;
  margin-top: 0px;
  //margin-left: 3.5%;
  //background-color: blue;
  min-width: 100%;
  overflow-x: hidden;
}

.productos-presentacion-carta {
  height: auto;
  //overflow-y: auto;
  border-radius: 20px;
  padding: 20px;
  margin-top: 10px;
  //background-color: red;
  width: 100%;
}

.proveedores-presentacion-carta {
  height: auto;
  //overflow-y: auto;
  border-radius: 20px;
  padding: 20px;
  margin-top: 10px;
  //background-color: rgb(0, 208, 255);
  width: 100%;
  //display: flex;
  //justify-content: space-around;
}

.todo-superior {
  display: flex;
  justify-content: space-around;
  width: 100%;
}


.parte-superior {
  //background-color: red;
  position: fixed;
  margin-top: 1%;
  height: 6vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 60;
  background-color: rgba(0, 0, 0, 0.253);
  backdrop-filter: blur(6px);
  border-radius: 50px;
  width: 80%;
}

.capsula-completa {
  //background-color: purple;
  margin-top: 0vh;
  //background-color: yellow;
  height: 75vh;
  margin-left: 1%;
  margin-top: 35px;
}

.capsula-completa-proveedores {
  //background-color: rgb(0, 0, 128);
  margin-top: 0vh;
  //background-color: yellow;
  height: auto;
  margin-left: 1%;
  display: flex;
}

.espacio-categorias {
  //background-color: purple;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titulo-inventario {
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.boton-nuevo-producto {
  //background-color: green;
  border-width: 2px;
  padding: 5px;
  border-color: black;
  font-size: 2rem;
  margin: 6px;
  border-radius: 50px;
  font-weight: bolder;
  color: blue;

  &:hover {
    background-color: gray;
  }
  
}

.categorias-tabs-inventario {
  gap: 10px;
  display: flex;
  justify-content: space-around;
  //flex-direction: column;
  background-color: rgba(220, 220, 220, 0);
  margin-left: 10px;
  border-radius: 80px;
  height: 80%;
}

.categoria-tab-inventario {
  cursor: pointer;
  font-weight: bolder;
  color: hsl(24.6 95% 53.1%);
  font-size: 2rem;
  transition: background-color 0.3s ease;
  border-radius: 0%;
  padding: 8px;
  font-family: 'Rubik', sans-serif;
  background-color: rgba(255, 255, 255, 0);
  margin-inline: 8px;
}

.categoria-tab-inventario.active {
  background-color: hsla(24.6 95% 53.1%);
  color: white;
  border-radius: 50%;
}

.buscador-inventario {
  display: flex;
  align-items: center;
  justify-content: center;
  //margin-left: 1%;
  font-family: 'Noto Sans Display', sans-serif;
  //background-color: red;
}

.buscador-inventario input {
  padding: 8px;
  font-size: 16px;
  color: black;
  //margin-right: 10px;
  //background-color: yellow;
  font-family: 'Rubik', sans-serif;
  border-radius: 8px;
  width: 200px;
  height: 4vh;
  font-family: 'Noto Sans Display', sans-serif;
}

.titulo-proveedores {
  background-color: rgba(199, 199, 199, 0.792);
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
}

.icono-proveedores {
  font-size: 3rem;
}