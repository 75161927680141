/* Metricas.scss */

.main-metricas {
    padding: 20px;
    background-color: hsl(210, 100%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100%;
  }
  
  .dashboard-container {
    background-color: red;
    min-width: 98%;
    display: grid;
    grid-template-areas:
      "total-revenue subscriptions calendar"
      "total-revenue subscriptions goals"
      "team-members team-chat team-chat"
      "exercise-minutes exercise-minutes exercise-minutes";
    gap: 20px;
    width: 100%;
    max-width: 1200px;
  }
  
  .dashboard-item {
    background-color: #1eff00;
    border: 1px solid #e3e6eb;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .dashboard-item h2 {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
  }
  
  .total-revenue {
    grid-area: total-revenue;
    background-color: blue;
    height: 60vh;
  }
  
  .subscriptions {
    grid-area: subscriptions;
    background-color: blue;
    height: 40vh;
  }
  
  .calendar {
    grid-area: calendar;
  }
  
  .goals {
    grid-area: goals;
  }
  
  .team-members {
    grid-area: team-members;
  }
  
  .team-chat {
    grid-area: team-chat;
  }
  
  .exercise-minutes {
    grid-area: exercise-minutes;
  }  