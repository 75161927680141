.main-proveedores{
    //background-color: red;
    height: auto;
    //margin-left: 3%;
    width: 100%;
    background-color: green;
}

.capsula-render-pro {
    //background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: blue;
}