.main-nomina {
  //background-color: yellow;
  width: 100%;
  min-height: 90vh;
  display: flex;
}

.lista-empleados {
    //background-color: rgb(0, 255, 64);
    max-height: 100vh;
    display: flex;
    //justify-content: center;
    align-items: center;

}

.capsula-pq-lista {
  //background-color: rgb(255, 0, 0);
  border-width: 0 0 0 3px;
  border-color: black;
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-wrap: wrap; /* Permite que las tarjetas se envuelvan en varias filas */
  justify-content: center;
  align-items: flex-start; /* Alinear las tarjetas en la parte superior */
  overflow-y: auto;
  margin-right: 5px;
  //border-radius: 10px;
}

.abajo-emp{
  //background-color: green;
  min-width: 100%;
  max-height: 99vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.capsula-pq-lista-sm {
  //background-color: rgba(0, 0, 0, 0.958);
  border-width: 0 0 0 3px;
  border-color: black;
  width: 100%;
  //max-height: 90vh;
  display: flex;
  flex-wrap: wrap; /* Permite que las tarjetas se envuelvan en varias filas */
  justify-content: center;
  align-items: flex-start; /* Alinear las tarjetas en la parte superior */
  overflow-y: auto;
  margin-right: 5px;
  //border-radius: 10px;
}

.card-empleado {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    //background-color: orange;
    min-height: 91%;
    width: 88%;
    border-width: 3px;
    //border-color: orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-family: 'Noto Sans Display', sans-serif;
    font-weight: bolder;
}

.foto-empleado {
    //background-color: red;
    height: auto;
    margin-top: 5px;
    text-align: center;
    color: white;
}

.nombre-empleado {
    //background-color: green;
    font-size: 2rem;
}

.icon-foto {
    font-size: 7rem;
    color: black;
}

.icon-foto-sm {
  font-size: 5rem;
  color: black;
}

.icon-foto-lista {
    font-size: 2rem;
    color: black;
}

.info-main-empleado {
    //background-color: blue;
    width: 80%;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.info-main-empleado-sm {
  //background-color: blue;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.info-main-empleado2 {
    background-color: rgba(0, 0, 0, 0);
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    border-radius: 20px;
    border-width: 2px;
    border-color: orange;
}

.info-main-empleado2-sm {
  //background-color: rgba(0, 0, 0, 0.799);
  width: 100%;
  margin-top: 10px; 
}

.info-contacto {
    width: 40%;
    padding-left: 10px;
    margin: 10px;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.info-contacto-sm {
  width: 100%;
  //padding-left: 10px;
  //margin: 10px;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background-color: green;
}

.info-empleado-sm {
  //background-color: green;
  width: 100%;
}

.back-gray {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 20px;
    border-width: 2px;
    padding: 10px;
    border-color: orange;
}

.info-horas {
    //background-color: red;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.info-horas-sm {
  //background-color: red;
  margin: 10px;
  display: flex;
  //flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.horas {
    background-color: rgba(192, 192, 192, 0.295);
    margin: 5px;
    width: 90%;
    text-align: center;
    border-radius: 6px;
    margin-top: 20px;
    //border-width: 3px;
    color: black;
}

.horas-sm {
  background-color: rgba(192, 192, 192, 0.295);
  //margin: 5px;
  width: 100%;
  text-align: center;
  border-radius: 6px;
  margin-top: 20px;
  //border-width: 3px;
  color: black;
}

.input-hora {
    background-color: rgba(0, 0, 0, 0.082);
    margin: 15px;
    padding: 6px;
    border-radius: 6px;
    text-align: center;
}

.guardar-hora {
    //background-color: red;
    border-radius: 5px;
    padding: 10px;
    //margin-bottom: 5px;
    color: white;
    font-size: 3rem;
    font-weight: bolder;
    background-color: rgba(0, 0, 0, 0.429);

}

.custom-swiper {
    width: 80%;
    height: 100%;
    //background-color: red;
    position: relative;
  }

  .custom-swiper-sm {
    //margin-top: 70vh;
    width: 100%;
    min-height: auto;
    //background-color: green;
    position: relative;
    //background-color: red;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .asd {
    //background-color: red;
    display: flex;
    align-items: center;
  }
  
  .swiper-slide {
    width: 50%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    position: relative;
  }

  .small-information {
    //background-color: red;
    font-size: 0.8rem;
  }

  .holi {
    //background-color: rgb(0, 153, 255);
    display: flex;
    justify-content: center;
  }

  .holi2 {
    //background-color: green;
    border-width: 2px;
    padding: 20px;
    width: calc(100% - 0px);
  }

  .select-empleado-sm {
    //background-color: red;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .swiper-slide-sm {  
    //background-color: rgb(0, 26, 255);
    border-radius: 20px;
    //position: absolute;
    margin-top: 20px;
    padding: 8px;
    display: flex;
    justify-content: center;
  }

  .opciones-nom{
    //background-color: green;
    margin: 5px;
    padding: 5px;
    border-radius: 20px;
    border-width: 2px;
    font-weight: bolder;
  }

  .opciones-nom-sm{
    //background-color: green;
    min-width: 20%;
    padding: 5px;
    border-radius: 8px;
    border-width: 2px;
    font-weight: bolder;
  }

  .selected-nom {
    background-color: orange;
    padding: 5px;
    margin: 5px;
    border-radius: 20px;
    font-weight: bolder;
    color: white;
  }

  .selected-nom-sm {
    background-color: orange;
    min-width: 20%;
    padding: 5px;
    border-radius: 8px;
    font-weight: bolder;
    color: white;
  }

  .footer-card-emp {
    //background-color: red;
    width: 90%;
    margin-top: 50px;
    justify-content: space-around;
    align-items: center;
  }

  .footer-card-emp-sm {
    //background-color: green;
    width: 100%;
    margin-top: 0px;
    justify-content: space-around;
    align-items: center;
  }

  .total-pagar-emp {
    //background-color: green;
    padding: 10px;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    width: 30%;
    text-align: center;
  }

  .total-pagar-emp-sm {
    //background-color: green;
    border-width: 1px;
    border-color: black;
    padding: 10px;
    border-radius: 2px;
    color: black;
    font-weight: bold;
    width: 40%;
    text-align: center;
    font-size: 0.9rem;
  }

  .boton-guardar-nom {
    //background-color: red;
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }

  .card-lista-emp {
    width: 90%;
    //background-color: red;
    height: 12vh;
    margin: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
    border-radius: 20px;
    border-width: 3px;
  }

  .card-lista-emp-sm {
    width: 90%;
    //background-color: red;
    height: 12vh;
    margin: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
    border-radius: 20px;
    border-width: 3px;
  }

  .info-final-emp {
    background-color: rgba(0, 0, 255, 0.045);
    text-align: center;
    padding: 5px;
    border-radius: 10px;
    width: 20%;
  }

  .nom-peq {
    //background-color: red;
    font-size: 0.8rem;
  }

  .emp-lista{
    //background-color: green;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info-opciones-sm {
    //background-color: blue;
    //margin-top: 15px;
    height: 8vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }