.container-producto-inventario {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
  padding: 15px;
  margin-bottom: 20px;
  transition: transform 0.7s ease;
  cursor: pointer;
  border-width: 2px;
  //border-color: orange;
  overflow: hidden;
}
.container-producto-inventario:hover {
  transform: translateY(-7px);
  background-color: rgba(128, 128, 128, 0.408);
  border-width: 0;
  //border-color: orangered;
}

.container-producto-inventario.editing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 75%;
  //overflow-y: auto;
  transform: translate(-50%, -50%) scale(1.2); /* escala y centra el contenedor */
  z-index: 1000; /* asegura que esté por encima de otros elementos */
  background-color: rgba(255, 255, 255, 0.981);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.755); /* Color oscuro con opacidad */
  z-index: 999; /* Asegura que esté por encima del contenido */
}


.image-container {
  display: flex;
  justify-content: center;
}

.image-inventario {
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.default-image {
  font-size: 48px;
  color: #ccc;
}

.InfoContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: red;
}

.info-main {
  color: black;
  font-size: 0.9rem;
  font-weight: bolder;
}

.info-second {
  color: rgb(97, 97, 97);
  font-size: 0.9rem;
  font-weight: bolder;
}
.Precio {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}
  .warning-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: red; /* Puedes cambiar el color según tus preferencias */
    font-size: 30px;
  }

  .delete-button {
    display: flex;
    //background-color: rgba(255, 0, 0, 0.623);
    border-width: 3px;
    border-color: black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: black;
    margin-top: 20px;
    &:hover {
      background-color: red;
      color: white;
      border-color: red;
    }
  }

  .informacion-adicional {
    //background-color: rgb(60, 255, 0);
    margin-bottom: 15px;
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    
  }

  .capsula-cantidad {
    background-color: rgba(177, 177, 177, 0.253);
    text-align: center;
    padding: 3px;
    border-radius: 10px;
  }

  .capsula-precio {
    background-color: rgba(151, 151, 151, 0);
    text-align: center;
    padding: 3px;
    border-radius: 10px;
    margin-right: 5px;
  }

  .ContainerProducto {
    width: 100%;
  }

  .ganancia {
    background-color: rgba(112, 112, 112, 0);
    width: 50px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    padding: 3px;
  }

  .capsula-ganancia {
    //background-color: red;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 3px;
    margin-bottom: 25px;
    background-color: rgba(189, 189, 189, 0.281);
  }

.icon-ganancia {
  background-color: green;
  color: white;
  height: 30px;
  width: 30px;
  padding: 1px;
  border-radius: 50%;
  margin-left: 17%;
}

.icon-perdida {
  background-color: red;
  color: white;
  height: 30px;
  width: 30px;
  padding: 1px;
  border-radius: 50%;
  margin-left: 17%;
}

.capsula-ediciones {
  //background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.edit-button {
  display: flex;
  //background-color: rgba(0, 128, 0, 0.671);
  color: rgb(0, 0, 0);
  border-width: 3px;
  border-color: black;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 20px;
  &:hover {
    background-color: red;
    color: white;
    border-color: red;
  }
}

.dos-botones {
  height: auto;
  display: flex;
  width: 60px;
  margin-top: 20px;
  //background-color: red;
}

.check {
  color: green;
  font-size: 1.5rem;
  //background-color: yellow;
  margin: 5px;
}

.cancel {
  color: red($color: #000000);
  font-size: 1.5rem;
  //background-color: yellow;
  margin: 5px;
}

.format-titulo {
  border-width: 2px;
  border-color: black;
  text-align: center;
  padding: 3px;
  width: 100%;
  border-radius: 40px;
}

.format-precio {
  border-width: 2px;
  border-color: black;
  width: 70px;
  border-radius: 40px;
  //background-color: rgba(0, 0, 0, 0.315);
  text-align: center;
}

.format-cantidad {
  width: 40px;
  border-radius: 40px;
  border-width: 2px;
  border-color: black;
  text-align: center;
}

.format-compra {
  border-width: 2px;
  border-color: black;
  width: 60px;
  text-align: center;
  border-radius: 40px;
}

.format-mensaje {
  border-width: 2px;
  border-color: black;
  width: 100%;
  height: 10vh;
  text-align: center;
  border-radius: 20px;
  padding: auto;
  overflow-y: auto;
}