.main-nomina25 {
    //background-color: red;
    min-width: 100%;
    min-height: 100vh;
    padding-left: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-nomina3 {
    //background-color: red;
    width: 100%;
    height: 100vh;
    padding-left: 1%;
    //display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}