.cocina-list-container {
    //background-color: red;
}
.producto-cocina {
    //background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: red;
}

.boton-hecho {
    //background-color: red;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boton-realizado {
    //background-color: blue;
    color: green;
    font-size: 4rem;
}

.realizado {
    border-color: green;
    background-color: rgba(0, 128, 0, 0.307);
  }
  
.borde-rojo {
    border-color: red;
    background-color: rgba(255, 0, 0, 0.149);
  }
  

.nombre-cocina {
    //background-color: green;
    margin-inline: 15px;
    font-size: 1.3rem;
    font-weight: bolder;
}

.cocina-item{
    //background-color: rgba(255, 0, 0, 0.077);
    border-width: 8px;
    //border-color: red;
    height: auto;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.mensaje-coc {
    background-color: rgba(0, 0, 0, 0.086);
    max-height: 20vh;
    overflow: auto;
    width: 100%;
    border-radius: 10px;
    margin-top: 15px;
    font-size: 1.8rem;
    color: red;
    font-weight: bolder;
}

.cocina-cantidad {
    background-color: red;
    color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    font-size: 1.5rem;
    border-radius: 7px;
    }

    .mesa-cocina {
        //background-color: green;
        display: flex;
    }

    .cocina-mesa {
        background-color: green;
        border-radius: 50%;
        color: white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bolder;
        font-size: 1.5rem;    
}


.hora-cocina {
    //background-color: red;
    margin-bottom: 15px;
    font-size: 1.2rem;
}

.cliente-cocina {
    font-weight: bolder;
}

.cliente-cocina-dom {
    font-weight: bolder;
    font-size: 1.2rem;
    color: red;      
}   

