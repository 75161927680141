/* Estilos para el overlay */
.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000062; /* Fondo negro con transparencia */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    /* Asegura que el overlay esté en la parte superior */
  }
 
  .ContainerModalProveedores {
    height: auto;
    overflow-y: auto;
    background-color: white; /* Fondo blanco */
    color: black; /* Texto negro */
    padding: 20px;
    margin: 20px;
    border-radius: 40px;
    text-align: center;
    position: relative;
    z-index: 3;
  }
  
  /* Estilos para el botón de cierre del modal */
  
  
  /* Estilos para el mensaje en el modal */
  .modal-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.text-atras {
    width: 48px;
    border-width: 3px;
    padding: 3px;
    border-radius: 30px;
    text-align: center;
    border-color: #4285F4;

    &:hover {
        background-color: #4285F4;
      }
}

.Titulo {
    width: 100%;
    font-weight: bold;
    color: black;
}

.Input {
    width: 100%;
    padding: 5px;
    border-radius: 15px;
    margin: 2vh 0;
    background-color: rgba(158, 158, 158, 0.386);
    color: black;
    height: 7vh;
}

.text-error {
    color: red;
}

.guardar {
    width: 25%;
    color: rgb(0, 0, 0);
    font-weight: bold;
    padding: 10px;
    border-radius: 30px;
    border-color: #4285F4;
    border-width: 3px;

    &:hover {
        background-color: #4285F4;
      }
}