.draggable-icon {
  position: fixed;
  cursor: pointer;
  z-index: 10000; /* Asegúrate de que esté por encima de otros elementos */
  //background-color: red;
  width: 50%;
  height: 2%;
}
.draggable-icon * {
  pointer-events: auto; /* Restaura la capacidad de respuesta a eventos del ratón para los elementos hijos */
}

.icono-flotante * {
  pointer-events: auto; /* Restaura la capacidad de respuesta a eventos del ratón para los elementos hijos */
}

.icono-flotante {
  background-color: rgba(17, 17, 17, 0.781);
  backdrop-filter: blur(2px);
  font-size: 3rem;
  border-radius: 5px;
  color: white;
  z-index: 10000;
}

.draggable-container {
  background-color: red;
  height: 50%;
  width: 50%;
}