/* Estilos para el overlay */
.modal-overlay-caja {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(8px); /* Fondo negro con transparencia */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    /* Asegura que el overlay esté en la parte superior */
  }
 
  .ContainerModalProveedores2 {
    max-height: 99vh;
    min-width: 100%;
    overflow-y: auto;
    //background-color: rgb(255, 0, 0); /* Fondo blanco */
    color: black; /* Texto negro */
    padding: 20px;
    margin: 20px;
    border-radius: 40px;
    text-align: center;
    position: relative;
    z-index: 3;
    background-color: rgb(255, 255, 255);
    backdrop-filter: blur(8px);
  }
  
  /* Estilos para el botón de cierre del modal */
  
  
  /* Estilos para el mensaje en el modal */
  .modal-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

/* ModalCajaTrabajadores.scss */
.text-atras {
  position: absolute;
  top: 10px; /* Ajusta según sea necesario */
  right: 10px; /* Ajusta según sea necesario */
  font-size: 1.5rem; /* Ajusta según sea necesario */
  cursor: pointer;
  z-index: 10; /* Asegúrate de que esté en el frente */
  &:hover {
    background-color: black;
  }
}

/* ModalCajaTrabajadores.scss */
.cerrar-caja-boton {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  background-color: #f00;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.cerrar-caja-boton:hover {
  background-color: #d00;
}



.Titulo {
    width: 100%;
    font-weight: bold;
    color: black;
}

.Input {
    width: 100%;
    padding: 5px;
    border-radius: 15px;
    margin: 2vh 0;
    background-color: rgba(158, 158, 158, 0.386);
    color: black;
    height: 7vh;
}

.text-error {
    color: red;
}

.guardar {
    width: 25%;
    color: rgb(0, 0, 0);
    font-weight: bold;
    padding: 10px;
    border-radius: 30px;
    border-color: #4285F4;
    border-width: 3px;

    &:hover {
        background-color: #4285F4;
      }
}
