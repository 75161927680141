.todo {
    width: 100%;
    //background-color: green;
    min-height: 100vh;
}

.modal {
    position: fixed; /* Se posiciona de forma fija sobre el contenido */
    z-index: 1; /* Se coloca por encima del resto del contenido */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.762); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content-calendarioo {
    position: relative;
    background-color: white;
    //backdrop-filter: blur(0px);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Sombra */
    width: auto;
    height: auto;
    margin-inline: 20px;
  }

  .hola {
    background-color: red;
    font-size: 1.5rem;
    color: black;
    margin-top: 5%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 5vh;
    align-items: center;
  }

  .hola-text {
    color: black;
    //margin: 0 5px 0 2px;
    font-size: 1.2rem;
    //background-color: black;
    height: 80%;
    width: 100%;
    margin-top: 5px;
  }
  
  .close-button {
    position: absolute;
    top: 2px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    //background-color: black;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: rgb(0, 0, 0);
    &:hover {
        background-color: black;
        color: white;
    }
  }

  .modal-content-calendario {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    color: white;
    font-weight: bolder;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 95%;

    h2 {
        margin-bottom: 20px;
    }

    button {
        padding: 10px 20px;
        font-size: 16px;
        color: #fff;
        background-color: #007bff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    }
}
  
  .calendario {
    /* Estilos específicos para el contenido del calendario */
    //background-color: blue;
    height: auto;
    width: auto;
    padding: 10px;
    color: black;
  }

.number-calendar {
    font-size: 0.8rem;
    font-weight: bold;
}

.capsula-facturas {
    background-color: rgb(115, 255, 0);
    min-width: 80%;
    min-height: 100vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.facturas-main {
    //background-color: rgba(0, 0, 0, 0.036);
    width: 100%;
    //border-radius: 20px;
    padding: 20px;
    //border-width: 3px;
    //border-color: black;
    //display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1%;
}

.header-factura {
    //background-color: red;
    height: 10vh;
    justify-content: space-around;
    align-items: center;
    font-size: 1.5rem;
    font-family: 'Noto Sans Display', sans-serif;
}

.icono-nofactura {
  font-size: 25rem;
}

.espacio-no {
  //background-color: red;
  //display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0vh;
}

.nofactura-letra {
  font-size: 2rem;
  font-family: 'Noto Sans Display', sans-serif;
}

.clientes-fiados {
  background-color: rgba(0, 0, 0, 0.034);
  height: 30vh;
  width: 30vh;
  border-radius: 10px;
  width: 30%;
}

.informacion-clientes {
  //background-color: red;
  padding-inline: 7%;
  margin-top: 10vh;
}

.informacion-factura-fiado {
  //background-color: rgba(0, 0, 0, 0.078);
  border-width: 1px;
  border-color: rgb(174, 174, 174);
  border-radius: 10px;
  width: 80%;
  margin-inline: 40px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.capsula-informacion-factura {
  background-color: rgb(224, 224, 224);
  margin-inline: 5px;
  padding: 5px;
  border-radius: 5px;
}