@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@200&family=Roboto+Condensed:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

// styles.scss

/* styles.css */

:root {
  --color1: black;
  --color2: hsl(346.8 77.2% 49.8%);
  --color3: hsl(221.2 83.2% 53.3%);
  --color4: hsl(142.1 76.2% 36.3%);
  //--color5: hsl(24.6 95% 53.1%);
  --color5: rgb(0, 0, 0);
}

.main-services {
  //margin-top: 10px;
  //background-color: blue;
  //margin-left: 2.5%;
  //height: 95vh;
  //margin-inline: 5px;
  //background-color: red;
  min-height: 100vh;
  //background-color: green;
  min-width: 100%;
  overflow-x: hidden;
}

.boton-crear {
    font-family: 'Noto Sans Display', sans-serif;
    border-width: 3px;
    border-color: #4285F4;
    width: 20%;
    height: 5vh;
    border-radius: 50px;
    &:hover {
      background-color: #4285F4;
      }
}

.Productos {
  height:73vh;
  //background-color: green;
  width: 100%;
  overflow-y: auto;
  border-radius: 20px;
  padding: 20px;
  margin-top: 0px;
  margin-left: 0px;
  //background-color: yellow;
  //margin-right: 240px;
}

.left{
  border-radius: 30px;
  //padding: 2px;
  min-width: 71%;
  //background-color: red;
  //padding-top: 25px;
  height: auto;
  //margin-left: 1%;
  margin-right: 0.4%;
  //background-color: green;
}

.input-nombre-cliente {
  background-color: rgba(128, 128, 128, 0.219);
  min-width: 80%;
  border-radius: 10px;
  height: 5vh;
  padding-inline: 10px;
  border-radius: 2px;
  //margin-left: 10px;
}

.seccion-cliente-reg {
  //background-color: red;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.Factura{
  min-width: 30%;
  height: 100vh;
  margin-top: 0px;
  padding: 10px;
  border-width: 0;
  border-radius: 5px;
  border-color: black;
  //background-color: rgb(255, 0, 0);
  //margin-right: 20%;
}

.todo-factura {
  //background-color: rgb(0, 255, 68);
  height:74vh;
  overflow-y: auto;
  padding-right: 7px;
  //background-color: blue;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 40%;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(173, 173, 173);
  border-radius: 5px;
  margin: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 0%);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.titulo {
  color: black;
  font-family: 'Noto Sans Display', sans-serif;
  text-align: center;
}

.cliente {
  margin: 10px;
  color: black;
}

.select {
  width: 50%;
  height: auto;
  border-radius: 2px;
  //margin-left: 20px;
  background-color: rgb(188, 188, 188);
  margin: 20px;
}

.capsula-producto {
  overflow-y: auto;
  height: 50vh;
  margin-top: 2px;
  //background-color: rgba(255, 166, 0, 0.07);
  border-radius: 7px;
}

.opciones-factura {
  //display: flex;
  margin-top:1vh;
  //background-color: rgb(0, 255, 51);
  //padding: 2px;
  height: auto;
  border-radius: 7px;
  //background-color: green;
}

.icon-agregar {
  color: black;
  //background-color: orange;
  border-radius: 5px;
  font-size: 2rem;

}

.icono-pago {
  color: rgb(255, 255, 255);
  font-size: 2.1rem;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  padding: 2px;
  border-width: 1px;
  border-color: black;
}

.capsula-cambios {
  //background-color: yellow;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.animated {
  &.fadeIn {
    animation: fadeIn 0.5s;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.capsula-metodos {
  //background-color: red;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  //align-items: center;
}

.agregar-metodo-pago {
  //background-color: blue;
  min-width: 62px;
}

.icono-opcion-peq {
  //background-color: green;
  font-size: 1.8rem;
  border-radius: 8px;
}

.select-metodo {
  //background-color: rgba(0, 0, 0, 0.552);
  border-width: 2px;
  border-color: black;
  border-radius: 4px;
  height: 4vh;
  width: 40%;
}

.input-metodo {
  //background-color: rgba(0, 0, 0, 0.552);
  border-width: 2px;
  border-radius: 2px;
  border-color: black;
  border-radius: 4px;
  height: 4vh;
  width: 40%;
  padding: 2px;
}

.botones-agregar-metodo {
 //background-color: red;
 font-size: 1.5rem;
}

.input-recibido {
  //background-color: rgba(0, 0, 0, 0.084);
  color: black;
  //height: 35px;
  width: 40%;
  height: 5vh;
  border-width: 2px;
  border-radius: 5px;
  border-color: black;
  padding: 8px;
  //margin-bottom: 10px;
  text-align: center;
  font-size: 1.3rem;
}

.input-cambio {
  background-color: rgba(0, 0, 0, 0.103);
  //height: 35px;
  height: 5vh;
  width: 40%;
  border-radius: 5px;
  padding: 5px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bolder;
}

.capsula-obs {
  //background-color: rgb(0, 60, 255);
  height: 7vh;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.observacion {
  background-color: rgba(128, 128, 128, 0.114);
  //background-color: black;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.div-botones-mesa {
  //background-color: rgba(255, 166, 0, 0.185);
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  //border-radius: 10px;
  border-width: 2px 0 0 0;
  border-color: black;
  //border-color: red;
  margin-top: 40px;  
  margin-left: 10px;
}

.botones-mesa {
  background-color: rgb(255, 0, 0);
  border-width: 2px;
  width: 35px;
  height: 35px;
  border-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-inline: 10px;
  margin-top: 8px;
}

.botones-mesa.active {
  background-color: black;
}

.informacion-producto {
  padding: 5px;
  margin: 8px;
  border-width:  0 0 1px 0;
  //background-color: rgb(255, 3, 3);
  border-radius: 10px;
  min-height: auto;
}

.seccion-venderr {
  margin: 5px;
  //padding: 10px;
  display: flex;
  justify-content: center;
  //background-color: rgb(8, 0, 255);
  border-radius: 7px;
  //margin-top: 10px;
}

.vender1 {
  background-color: black;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 10px;
  margin-inline: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vender2 {
  background-color: green;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 10px;
  margin-inline: 1%;
}

.text-factura {
  font-weight: bold;
  font-size: 20px;
  color: white;
  font-family: 'Noto Sans Display', sans-serif;
}


.sumar2 {
  background-color: rgb(0, 0, 0);
  color: white;
  border-width: 0px;
  border-color: black;
  border-radius: 50px;
  padding: 5px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
/* Estilos para el mensaje de advertencia de cantidad insuficiente */
.modal-warning {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: orange;
  padding: 10px;
  width: auto;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

/* Estilos para el mensaje de error de cantidad excedida */
.error-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.699);
  z-index: 1000;
  text-align: center;
  font-family: 'Noto Sans Display', sans-serif;
}

.error-message button,
.modal-warning button {
  margin: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 20px;
  color: black;
  border: none;
  cursor: pointer;
}

.error-message button:hover,
.modal-warning button:hover {
  background-color: gray;
}

.adicionar {
  font-size: 50px;
  padding: 2px;
  margin: 10px;
  color: black;
  font-family: 'Noto Sans Display', sans-serif;
}

.tituloModal {
  text-align: center;
  margin: 20px;
  color: black;
  font-family: 'Noto Sans Display', sans-serif;
  //background-color: red;
}

.Modal {
  /* Ajusta el estilo del modal a tu preferencia, como posición y dimensiones */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  max-width: 600px;
  background-color: white;
  //background-color: rgb(255, 255, 255);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.grid-productos {
  //background-color: blue;
  min-width: 100%;
}

.plus {
  //background-color: red;
  font-size: 1.5rem;
}
.modal-options {
  //background-color: blue;
  //display: flex;
  margin-bottom: 5%;
  width: 100%;
  //background-color: green;
}

.categorias-tabs-sm {
  //background-color: blue;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 10%;
}

.categoria-tab-sm {
  //background-color: yellow;
  display: flex;
  justify-content: space-around;
  font-size: 30px;
  margin-inline: 5px;
  width: 30px;
  height: 30px;
  color: rgb(67, 67, 67);
}

.categoria-tab-sm.active {
  //padding: 8px;
  //background-color: black;
  color: rgb(0, 0, 0);
  //border-radius: 50%;
}

.buscador-sm input {
  //background-color: rgb(13, 0, 255);
  border-width: 3px;
  border-color: black;
  border-radius: 80px;
  color: black;
  padding: 5px;
  width: 90%;
}

.buscador-sm {
  //background-color: blue;
  border-radius: 80px;
  width: 100%;
  justify-content: center;
}

/* Estilos para el botón de cierre*/ 
.closebutton {
  /* Ajusta el estilo del botón de cierre */
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

.modal-contentt {
  width: 100%;
  height: 100%;
  //background-color: red;
  //overflow-y: auto;
  //background-color: yellow;
}

.modal-bodyy {
  height: 88vh;
  overflow-y: auto;
  //background-color: aqua;
}

.card-container {
  display: flex; /* O utiliza "display: grid;" si prefieres */
  justify-content: center;
  flex-wrap: wrap; /* Esto permite que las cartas se envuelvan a la siguiente fila */
  //gap: 16px; /* Espacio entre las cartas */
  //background-color: green;
  min-width: 100%;
  //background-color: red;
  padding: 5px;
}

.no-product-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%; /* Esto ajusta la altura del div para centrar verticalmente */
  font-family: 'Noto Sans Display', sans-serif;
  font-weight: bolder;
}

.big-icon {
  font-size: 120px;
  color: black; 
}

.no-selected {
  font-weight: bold;
  color: black;
}

/* Estilos para las pestañas de categorías */
.categorias-tabs {
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  margin-top: 0vh;
  //background-color: red;
  //background-color: red;
}

.categoria-tab {
  //background-color: green;
  cursor: pointer;
  font-weight: bolder;
  color: rgb(0, 0, 0);
  font-size: 1.4em;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  padding: 8px;
  font-family: 'Rubik', sans-serif;
  margin-inline: 5px;
  //border-width: 1px;
  border-radius: 50%;
  border-color: black;
}

.categoria-tab:hover {
  background-color: gray;
  color: white;
}

/* Estilo para la pestaña activa */
.categoria-tab.active {
  padding: 8px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 50%;
}

/* Estilos para la barra de búsqueda */
.buscador {
  //background-color: rgb(132, 255, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  //margin-left: 1%;
  font-family: 'Noto Sans Display', sans-serif;
  //background-color: red;
  margin-left: 20px;
  border-color: black;
}

.buscador input {
  //background-color: rgb(13, 0, 255);
  padding: 8px;
  font-size: 16px;
  color: black;
  margin-right: 10px;
  //background-color: yellow;
  font-family: 'Rubik', sans-serif;
  border-width: 1px;
  border-color: black;
  border-radius: 5px;
  width: 220px;
  height: 5vh;
  font-family: 'Noto Sans Display', sans-serif;
}

.titulo-factura {
  font-size: 0.8rem;
  font-weight: bolder;
  font-family: 'Noto Sans Display', sans-serif;
  width: 100%;
}

.image-peq {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-services-1 {
  border-radius: 20px;
  width: 50px;
  height: 50px;
}

.barra-opciones {
  display: flex;
  //background-color: red;
  max-width: 90%;
  //background-color: rgba(0, 0, 0, 0.644);
  border-radius: 50px;
  margin-inline: 5%;
}

.comidas-tab {
  //background-color: red;
  height: 7vh;
  display: flex;
  justify-content: space-between;
  width: auto;
  align-items: center;
}

.delivery {
  color: white;
  font-size: 2rem;
  background-color: orange;
  border-radius: 50%;
}

.alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: red;
  border: 1px solid #ccc;
  color: white;
  font-weight: bolder;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nombre-usuario {
  font-weight: bolder;
  color: black;
  text-decoration: overline;
}

.error-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ff0000; /* Color de fondo del mensaje */
  color: #ffffff; /* Color del texto del mensaje */
  padding: 10px 20px; /* Espaciado interno del mensaje */
  border-radius: 5px; /* Borde redondeado */
  z-index: 1000; /* Asegurar que esté encima de otros elementos */
}

.icono-x {
  color: black;
}

.refresh {
  border-radius: 10px;
  background-color: black;
  color: white;
  padding: 5px;
  font-size: 1.5rem;
  &:hover {
    background-color: #0000009a;
    }
}

.mesas-sm {
  display: flex;
  overflow-x: auto; // Habilita el desplazamiento horizontal
  white-space: nowrap; // Asegura que todos los elementos estén en una sola línea
  max-width: 100vw; // Limita el ancho al de la ventana
  padding: 5px 10px; // Añade algo de padding interno
  box-sizing: border-box; // Asegura que el padding no afecte el ancho calculado
}


.botones-mesa-sm {
  flex: 0 0 auto; // Evita que los botones se estiren o encojan
  width: 32px;
  height: 32px;
  margin: 0 5px; // Margen horizontal entre botones
  border-radius: 8px;
  border: 1px solid black; // Borde para mejor visualización
  background-color: green;
  cursor: pointer; // Indica que el elemento es interactuable
  display: flex;
  align-items: center;
  justify-content: center;
}

.as {
  //background-color: red;
  font-size: 1.5rem;
}


.abono-section {
  background-color: rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  margin-bottom: 10px;
  border-radius: 10px;
}

.input-abono {
  background-color: rgba(0, 0, 0, 0.137);
  margin-left: 20px;
  border-radius: 10px;
  height: 6vh;
  padding: 5px;
}

.message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  z-index: 1000; /* Asegúrate de que esté sobre otros elementos */
}

.message-content {
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  width: 300px;
}

.icono-delivery {
  background-color: red;
  //font-size: 2.5rem;
  border-radius: 18%;
  width: 70px;
  height: 40px;
  //margin-left: 10px;
  //margin: 10px;
}

.voice-command-container {
  position: fixed;
  top: 0;  /* Extiende el contenedor a toda la pantalla */
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center; /* Asegura que el contenido esté centrado */
  z-index: 1000; /* Mantiene el contenedor por encima de otros elementos */
  transition: all 0.5s ease-in-out; /* Transición suave para el movimiento */
  pointer-events: none; /* Evita que el contenedor capture clics cuando no esté activo */
}

.floating-icon {
  position: absolute; /* Posiciona absolutamente dentro del contenedor */
  top: 350px; /* Posición inicial en la parte superior derecha */
  right: 10px;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 2.5rem;
  transition: all 0.5s ease-in-out;
  pointer-events: auto; /* Asegura que el ícono pueda capturar clics */
}

.floating-icon.active {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1); // Ajusta la posición para centrar exactamente
  width: 100px; // Asegura dimensiones explícitas
  height: 100px; // Asegura dimensiones explícitas
  border-radius: 100%; // Asegura que el ícono sea completamente circular
  background-color: black; // Cambia el color de fondo a negro
  animation: pulse 2s infinite; // Aplica la animación de pulso
  -webkit-animation: pulse 2s infinite; /* Para Safari */
  color: black;
  font-size: 10rem; // Aumenta el tamaño del ícono
}


.message {
  position: absolute;
  top: 70%;  /* Posición justo debajo del icono, ajusta según sea necesario */
  left: 50%;  /* Centra horizontalmente el mensaje bajo el icono */
  transform: translateX(-50%); /* Ajusta para centrar el texto exactamente debajo del icono */
  color: black; /* Color del texto */
  font-size: 1.5rem; /* Tamaño del texto */
  text-align: center; /* Asegura que el texto esté centrado */
  pointer-events: none; /* Evita que el mensaje capture clics */
  //background-color: rgba(0, 0, 0, 0.692);
  font-size: 2.5rem;
  padding: 10px;
  border-radius: 50px;
  backdrop-filter: blur(2px);
  font-weight: bolder;
  min-width: 100%;
}

.message2 {
  position: absolute;
  top: 20%;  /* Posición justo debajo del icono, ajusta según sea necesario */
  left: 50%;  /* Centra horizontalmente el mensaje bajo el icono */
  min-width: 80%;
  min-height: 5vh;
  transform: translateX(-50%); /* Ajusta para centrar el texto exactamente debajo del icono */
  color: white; /* Color del texto */
  font-size: 1.5rem; /* Tamaño del texto */
  text-align: center; /* Asegura que el texto esté centrado */
  pointer-events: none; /* Evita que el mensaje capture clics */
  background-color: rgba(0, 0, 0, 0.563);
  font-size: 1rem;
  padding: 10px;
  border-radius: 50px;
  backdrop-filter: blur(2px);
  //font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    border-radius: 100%;
  }
  50% {
    -webkit-box-shadow: 0 0 0 100px rgba(255, 0, 0, 0);
    border-radius: 100%;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0);
    border-radius: 100%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    border-radius: 100%;
  }
  50% {
    box-shadow: 0 0 0 100px rgba(255, 0, 0, 0);
    border-radius: 100%;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 81, 255, 0);
    border-radius: 100%;
  }
}



.overlay-main {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semi-transparente */
  z-index: 500; /* Menor que el ícono para mantener el ícono sobre la superposición */
  backdrop-filter: blur(2px);
}

.overlay-main.active {
  display: block;
}

